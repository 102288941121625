body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-calendar-picker-indicator {
  filter:
    invert(1)
}

.date {
  width: 100%;
  height: 100%;
  padding-left: 5px;
}

.react-datepicker__input-container input {
  width: inherit;
  height: 40px;
  border: #B5CFD8;
  border-radius: 5px;
  padding-left: 16px;
}

.dateColorDark .react-datepicker__input-container input {
  background-color: var(--chakra-colors-gray-700);
}

.dateColorLight .react-datepicker__input-container input {
  background-color: var(--chakra-colors-whiteAlpha-50);
}

.react-datepicker-ignore-onclickoutside:focus {
  outline: none;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: var(--chakra-colors-brand-500) !important;
}

.invalid {
  border: 2px solid #e53e3e !important;
}

.valid {
  border: 1px solid #e2e8f0 !important;
}

.valid:focus,
.invalid:focus {
  border: 2px solid var(--chakra-colors-brand-500) !important;
}

.rct-item {
  border: none !important
}

.rct-header-root {
  margin-top: 20px;
  background-color: var(--chakra-colors-brand-600) !important;
  font-size: 22px !important;
}

.rct-outer {
  border: none !important;
}

.rct-vl {
  position: relative;
  z-index: 1 !important;
  margin-left: -1px !important;
}

.react-calendar-timeline .rct-dateHeader {
  border-left: 1px solid #bbb !important;
  background-color: 1px solid var(--chakra-colors-brand-600) !important;
}

.rct-dateHeader span {
  font-size: 14px;
}

.month-name span {
  font-size: 26px !important;
}

.rct-outer {
  border-radius: 0 10px 10px 10px;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.rct-header-root {
  border-top: 1px solid lightgray;
  border-radius: 10px 10px 0 0;
}

.react-calendar-timeline .rct-calendar-header {
  border: unset !important;
  position: sticky !important;
}

.rct-dateHeader-primary {
  font-size: 24px !important;
}

.rct-header-root {
  font-size: 22px !important;
  margin: 0;
}

.rct-sidebar-row,
.rct-sidebar-row-odd {
  display: flex;
  justify-content: left;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 28px !important;
  color: var(--chakra-colors-brand-600) !important;
  font-weight: bold;
}

.fontColorDark {
  color: white !important;
}

.fontColoLight {
  color: black !important;
}

.rbc-active {
  background-color: var(--chakra-colors-brand-600) !important;
  color: white !important;
}

.rbc-today {
  background-color: var(--chakra-colors-brand-600) !important;
}

.a {
  width: 100%;
  background-color: var(--chakra-colors-brand-600);
  font-size: 16px;
  color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
  height: 40px !important;
}

.rbc-button-link {
  width: 100%;
}

.rbc-header {
  height: 40px;
}

.rbc-time-view .rbc-label {
  display: none;
}

.rbc-time-view .rbc-day-bg {
  display: none;
}

.rbc-time-content {
  display: none !important;
}

input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.rbc-event {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-event .rbc-selected {
  width: 100%;
  height: 100%;
}

.rbc-event-content {
  width: 100%;
  height: 100%;
}

.users:hover svg path {
  fill: #27a7a7;
  stroke: #27a7a7;
}

.rct-scroll {
  overflow: hidden !important;
}

.css-vooagt {
  overflow-y: auto;
}

.rbc-event-content {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.rbc-header {
  padding: 0 !important;
}
.rct-item-content{
  width: 100%;
  height: 100%;
  padding: 0 !important;
}